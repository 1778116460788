<template>
  <div class="h-full flex flex-col">
    <search-bar
      v-model="search"
      :label="$t('App.SearchRoomLabel').toString()"
      :placeholder="$t('App.SearchRoomPlaceholder').toString()"
      @input="update"
    />
    <advanced-search
      v-show="showAdvanced"
      :available-equipment="availableEquipment"
      :buildings="buildings"
      :building="building"
      :locations="locations"
      :location="location"
      :capacities="capacities"
      :capacity="capacity"
      :equipment="equipment"
      :only-free="onlyFree"
      @update-capacity="updateCapacity"
      @update-equipment="updateEquipment"
      @update-building="updateBuilding"
      @update-location="updateLocation"
      @update-only-free="updateOnlyFree"
      @search="update"
    />
    <div
      class="flex justify-end items-center text-microsoft-blue-default hover:text-black cursor-pointer"
      :class="{'mt-[7px]': !showAdvanced}"
      @click="showAdvanced = !showAdvanced"
    >
      <p class="text-xs">
        {{ $t('App.AdvancedSearch') }}
      </p>
      <img
        class="arrow-icon transition-all duration-200 ease-in mx-2"
        :class="{ 'inverted': showAdvanced }"
        :src="require('@/assets/img/arrow-down.svg')"
        alt="arrow down"
      >
    </div>
    <app-loading
      v-if="loading"
      :loading="$t('App.LoadingData')"
      class="flex flex-col justify-start mt-5 items-center grow"
    />
    <div
      v-else
      class="flex-grow flex flex-col"
    >
      <div class="flex items-center text-neutral-gray-700">
        <div
          v-if="bookables.length"
          class="text-xs mr-4"
        >
          {{ $tc('App.RoomsFound', bookables.length, { count: bookables.length }) }}
        </div>
        <div class="divider grow" />
      </div>
      <div
        v-if="bookables.length > 0"
        class="flex flex-col overflow-y-auto grow"
      >
        <bookable-list-element
          v-for="bookable of bookables"
          :key="bookable.id"
          class="border border-solid hover:border-neutral-gray-700 border-neutral-gray-400 transition-all duration-200"
          style="min-height: 80px; height: 100%"
          :bookable="bookable"
          :attendees="attendees"
          :locations="locations"
          :buildings="buildings"
          @select="instance => $emit('select-bookable', instance)"
        />
      </div>
      <div v-else-if="bookables.length === 0">
        <h5 class="mt-6 font-medium text-neutral-gray-700 text-center">
          {{ $t('App.NoResults') }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import AdvancedSearch from '@/components/AdvancedSearch'
import AppLoading from '@/components/AppLoading'
import BookableListElement from '@/components/BookableListElement'
import SearchBar from '@/components/SearchBar'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

export default {
  components: {
    AdvancedSearch,
    BookableListElement,
    SearchBar,
    AppLoading,
  },
  props: {
    attendees: {
      required: true,
      type: Array,
    },
    loading: {
      required: true,
      type: Boolean,
    },
    availableEquipment: {
      required: true,
      type: Array,
    },
    bookables: {
      required: true,
      type: Array,
    },
    buildings: {
      required: true,
      type: Array,
    },
    locations: {
      required: true,
      type: Array,
    },
    params: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      capacity: null,
      search: '',
      equipment: [],
      building: null,
      location: null,
      onlyFree: true,
      showAdvanced: false,
    }
  },
  computed: {
    capacities () {
      return [
        { name: this.$t('App.AllRooms'), value: [0] },
        ...['1-4', '5-9', '10-14', '15-19', '20-29', '30-49', '50-99', '100']
          .map((c, index, arr) => ({
            name: index === arr.length - 1 ? `${c}+` : c,
            value: c.split('-').map(n => +n),
          })),
      ]
    },
  },
  mounted () {
    this.initFromParams()
  },
  methods: {
    initFromParams () {
      this.search = this.params.search ?? ''

      if (this.params.equipment && this.params.equipment.length > 0) {
        this.equipment = this.availableEquipment
          .filter(e => this.params.equipment.includes(e.id)) ?? []
      }

      this.capacity = this.capacities.find(c => isEqual(
        c.value,
        [this.params.min_capacity, this.params.max_capacity].filter(v => v !== undefined),
      )) ?? null

      this.location = this.locations.find(l => l.id === this.params.location) ?? null

      this.building = this.buildings.find(b => b.id === this.params.building) ?? null

      this.onlyFree = !!this.params.only_free

      this.showAdvanced = this.equipment.length > 0 || this.capacity !== null || this.location !== null || this.building !== null || !this.onlyFree
    },
    updateBuilding (building) {
      this.building = building
      if (building && this.location && building.id !== this.location.building_id) {
        this.location = null
      }
    },
    updateCapacity (capacity) {
      this.capacity = capacity
    },
    updateLocation (location) {
      this.location = location
    },
    updateEquipment (equipment) {
      this.equipment = [...equipment]
    },
    updateOnlyFree (value) {
      this.onlyFree = value
    },
    update: debounce(function () {
      const params = {
        search: this.search,
        min_capacity: this.capacity?.value && typeof this.capacity.value[0] === 'number' ? this.capacity.value[0] : undefined,
        max_capacity: this.capacity?.value && this.capacity.value[1] ? this.capacity.value[1] : undefined,
        building: this.building ? this.building.id : undefined,
        location: this.location ? this.location.id : undefined,
        equipment: this.equipment && this.equipment.length > 0 ? this.equipment.map(e => e.id) : undefined,
        only_free: this.onlyFree ? 1 : 0,
      }

      if (isEqual(this.params, params)) {
        return
      }

      this.$emit('search', params)
    }, 300),
  },
}
</script>

<style lang="scss" scoped>
.arrow-icon {
  height: 0.4375rem;
}

.loading {
  width: 4rem;
}

.inverted {
  transform: scaleY(-1);
}
</style>
