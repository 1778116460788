<template>
  <div class="pb-4">
    <div>
      <span class="text-neutral-gray-500 text-xs mb-1 pl-2 font-medium">
        {{ $t('App.NumberOfParticipantsLabel') }}
      </span>
      <multiselect
        v-model="syncedCapacity"
        class="multiselect-is-single"
        :close-on-select="true"
        :placeholder="$t('App.NumberOfParticipantsPlaceholder')"
        :options="capacities"
        :max-height="200"
        label="name"
        open-direction="bottom"
        track-by="value"
        @input="update"
      />
    </div>
    <div>
      <span class="text-neutral-gray-500 text-xs mb-1 pl-2 font-medium">{{ $t('App.Building') }}</span>
      <multiselect
        v-model="syncedBuilding"
        class="multiselect-is-single"
        :close-on-select="true"
        :placeholder="$t('App.BuildingPlaceholder')"
        :options="buildings"
        label="name"
        open-direction="bottom"
        track-by="id"
        @input="update"
      />
    </div>
    <div>
      <span class="text-neutral-gray-500 text-xs mb-1 pl-2 font-medium">{{ $t('App.Location') }}</span>
      <multiselect
        v-model="syncedLocation"
        class="multiselect-is-single"
        :close-on-select="true"
        :placeholder="$t('App.LocationPlaceholder')"
        :options="locations"
        :custom-label="locationLabel"
        open-direction="bottom"
        track-by="id"
        @input="update"
      />
    </div>
    <div>
      <span class="text-neutral-gray-500 text-xs mb-1 pl-2 font-medium">{{ $t('App.Equipment') }}</span>
      <multiselect
        v-model="syncedEquipment"
        class="multiselect-is-multiple"
        :close-on-select="false"
        :multiple="true"
        :placeholder="$t('App.EquipmentPlaceholder')"
        :options="availableEquipment"
        :max-height="400"
        label="name"
        open-direction="bottom"
        track-by="id"
        @input="update"
      />
    </div>
    <label class="form-switch mr-3 mt-1">
      <input
        v-model="syncedOnlyFree"
        type="checkbox"
        @input="update"
      >
      <i />
      <span class="text-neutral-gray-500 text-xs mb-1 pl-2 font-medium">{{ $t('App.OnlyFree') }}</span>
    </label>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'AdvancedSearch',
  components: { Multiselect },
  props: {
    buildings: {
      required: true,
      type: Array,
    },
    locations: {
      required: true,
      type: Array,
    },
    availableEquipment: {
      required: true,
      type: Array,
    },
    equipment: {
      required: true,
      type: Array,
    },
    building: {
      required: true,
      validator: (value) => typeof value === 'object' || value == null,
    },
    location: {
      required: true,
      validator: (value) => typeof value === 'object' || value == null,
    },
    capacity: {
      required: true,
      validator: Object,
    },
    capacities: {
      type: Array,
      required: true,
    },
    onlyFree: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    syncedEquipment: {
      get () {
        return this.equipment
      },
      set (eq) {
        this.$emit('update-equipment', eq)
      },
    },
    syncedBuilding: {
      get () {
        return this.building
      },
      set (building) {
        this.$emit('update-building', building)
      },
    },
    syncedLocation: {
      get () {
        return this.location
      },
      set (loc) {
        this.$emit('update-location', loc)
      },
    },
    syncedCapacity: {
      get () {
        return this.capacity
      },
      set (cap) {
        const value = cap ?? []
        this.$emit('update-capacity', value)
      },
    },
    syncedOnlyFree: {
      get () {
        return this.onlyFree
      },
      set (value) {
        this.$emit('update-only-free', value)
      },
    },
  },
  methods: {
    update () {
      this.$emit('search')
    },
    locationLabel (location) {
      if (this.building) {
        return location.name
      }

      return `${this.buildings.find(b => b.id === location.building_id).name} — ${location.name}`
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
