<template>
  <div>
    <div class="font-medium text-flexopus-black">
      <h3>{{ userName }}</h3>
      <h5 class="text-xs text-microsoft-blue-default flex">
        {{ userEmail }}
        <span class="transition-all duration-200 hover:brightness-0 hover:saturate-100">
          <img
            v-if="!isCopied"
            class="w-[0.8rem] ml-2 cursor-pointer"
            :src="require('@/assets/img/copy-icon.svg')"
            alt="copy"
            @click="copy"
          >
          <img
            v-else
            class="w-[0.8rem] ml-2"
            :src="require('@/assets/img/check-icon.svg')"
            alt="copied"
          >
        </span>
      </h5>
    </div>
    <div class="divider my-6" />
    <div class="text-xs">
      <div class="flex justify-between text-neutral-gray-700">
        <h6 class="font-bold text-neutral-gray-700">
          {{ $t('App.Tenant') }}
        </h6>
        <a
          class="cursor-pointer font-medium underline text-microsoft-blue-default"
          target="_blank"
          :href="tenant"
        >
          {{ tenant }}
        </a>
      </div>
      <div class="flex justify-between mt-2 text-neutral-gray-700">
        <h6 class="font-bold">
          {{ $t('App.ReleaseDate') }}
        </h6>
        <h6 class="font-medium">
          {{ parsedReleaseDate }}
        </h6>
      </div>
      <div class="flex justify-between mt-2 text-neutral-gray-700">
        <h6 class="font-bold">
          {{ $t('App.Version') }}
        </h6>
        <h6 class="font-medium">
          {{ version }}
        </h6>
      </div>
    </div>
    <div class="divider my-6" />
    <plugin-details />
    <div class="flex justify-end">
      <button
        class="btn-secondary hover:border-black focus:border-microsoft-blue-default text-[12px] w-1/3 transition-all duration-200"
        @click="onLogout"
      >
        {{ $t('App.SignOut') }}
      </button>
    </div>
  </div>
</template>

<script>
import PluginDetails from '@/components/PluginDetails'

export default {
  components: {
    PluginDetails,
  },
  props: {
    version: {
      required: true,
      type: String,
    },
    releaseDate: {
      required: true,
      type: String,
    },
    userName: {
      required: true,
      type: String,
    },
    userEmail: {
      required: true,
      type: String,
    },
    tenant: {
      required: true,
      type: String,
    },
  },
  data () {
    return {
      isCopied: false,
    }
  },
  computed: {
    parsedReleaseDate () {
      const date = new Date(this.releaseDate)

      if (isNaN(date)) {
        return 'N/A'
      }

      return date.toLocaleString()
    },
  },
  methods: {
    onLogout () {
      this.$emit('logout')
    },
    async copy () {
      // The document.execCommand method is obsolete but navigator.clipboard is not available
      // because Microsoft did not add allow="clipboard-write" to it's iframe tag.
      const input = document.createElement('input')
      input.type = 'text'
      input.value = this.userEmail
      document.body.append(input)
      input.select()
      document.execCommand('copy')
      input.remove()
      this.isCopied = true

      setTimeout(() => {
        this.isCopied = false
      }, 500)
    },
  },
}
</script>

<style scoped>
</style>
