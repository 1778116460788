<template>
  <div
    :style="style"
    class="tooltip"
  >
    <div class="tooltip__container">
      <h4 class="tooltip__title text-xs">
        {{ $t('App.MeetingRoom', { name }) }}
      </h4>
      <h6 class="tooltip__description-text text-xs">
        {{ capacity ? $tc('App.Capacity', capacity, { capacity: capacity }) : $t('App.NoCapacity') }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      required: true,
      type: Object,
    },
    width: {
      required: false,
      type: Number,
      default: 160,
    },
    height: {
      required: false,
      type: Number,
      default: 80,
    },
    scale: {
      required: false,
      type: Number,
      default: 1,
    },
    parent: {
      required: false,
      type: HTMLDivElement,
      default: null,
    },
    name: {
      required: true,
      type: String,
    },
    capacity: {
      required: false,
      type: Number,
      default: 0,
    },
    offset: {
      required: false,
      type: Array,
      default: () => [0, 0],
    },
  },
  computed: {
    domHeight () {
      return `${this.width * this.scale}px`
    },
    domWidth () {
      return `${this.height * this.scale}px`
    },
    elementPosition () {
      let position = {
        left: this.position.left + this.offset[0],
        top: this.position.top + this.offset[1],
      }
      if (!parent) {
        return position
      }

      const rect = this.parent.getBoundingClientRect()
      if (rect.right < position.left + this.width) {
        position = { ...position, left: position.left - this.width - this.offset[0] }
      }

      if (rect.bottom < position.top + this.height) {
        position = { ...position, top: position.top - this.height - this.offset[1] }
      }

      return position
    },
    style () {
      return `
          left: ${this.elementPosition.left}px;
          top: ${this.elementPosition.top}px;
          width: ${this.domWidth}px;
          height: ${this.domHeight}px;
       `
    },
  },
}
</script>

<style lang="scss" scoped>

.tooltip {
  padding: .825rem .75rem;
  width: auto;
  max-width: 17rem;
  font-size: 0.85rem;
  @apply fixed bg-white;
  @apply border border-neutral-gray-400 z-50 rounded;
  @apply text-flexopus-black;

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    align-items: center;
    margin-top: 1px;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__name {
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__description-text {
    font-weight: normal;
    white-space: pre-wrap;
  }
}
</style>
