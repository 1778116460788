import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'

import './assets/scss/main.scss'

import 'css.escape'

Vue.config.productionTip = false
window.Office.onReady(() => {
  new Vue({
    i18n,
    render: h => h(App),
  }).$mount('#app')
})
