<template>
  <div class="relative">
    <pan-zoom-icons
      @zoom-in="zoomIn"
      @zoom-out="zoomOut"
      @reset="zoomReset"
    />
    <div
      ref="svgHolder"
      class="svg-holder"
      @mousedown="startDragging"
      @mouseenter="startGrab"
      @mouseup="startGrab"
      @mouseleave="startDefault"
    />
    <fixed-tooltip
      v-if="showTooltip"
      :parent="$refs.svgHolder"
      :scale="scale"
      :position="positions"
      :name="name"
      :capacity="capacity"
      :offset="[0, 20]"
    />
  </div>
</template>

<script>
import PanZoomIcons from '@/components/PanZoomIcons'
import svgPanZoom from 'svg-pan-zoom'
import axios from 'axios'
import FixedTooltip from '@/components/FixedTooltip'
import outlook from '@/outlook'

export default {
  components: { FixedTooltip, PanZoomIcons },
  props: {
    selected: {
      type: String,
      required: true,
    },
    map: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    capacity: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data: function () {
    return {
      cleanupListeners: () => {},
      zoomIn: () => {},
      zoomOut: () => {},
      zoomReset: () => {},
      svg: null,
      showTooltip: false,
      positions: { left: 0, top: 0 },
      bookableTarget: null,
      scale: 1,
    }
  },
  async mounted () {
    this.$refs.svgHolder.innerHTML = (await axios.get(this.map, {
      headers: {
        Authorization: 'Bearer ' + outlook.getSetting('token'),
      },
    })).data
    this.svg = this.$refs.svgHolder.children[0]
    this.addClass(this.svg, 'floorplan')
    this.initSvg()
  },
  beforeDestroy () {
    this.cleanupListeners()
  },
  methods: {
    addClass (element, className) {
      if (element.classList) {
        element.classList.add(className)
      } else {
        element.className.baseVal = (element.className.baseVal + ' ' + className).trim()
      }
    },
    initSvg () {
      svgPanZoom(this.svg, {
        viewportSelector: '.svg-pan-zoom_viewport',
        panEnabled: true,
        controlIconsEnabled: false,
        zoomEnabled: false,
        mouseWheelZoomEnabled: true,
        preventMouseEventsDefault: true,
        zoomScaleSensitivity: 0.2,
        minZoom: 0.5,
        maxZoom: 10,
        fit: true,
        contain: false,
        center: true,
        refreshRate: 'auto',
        customEventsHandler: {
          init: (options) => {
            this.zoomIn = () => options.instance.zoomIn()
            this.zoomOut = () => options.instance.zoomOut()
            this.zoomReset = () => options.instance.reset()

            this.zoom(options.instance)

            options.instance.setOnZoom(() => {
              this.scale = options.instance.getZoom()
            })
          },
        },
      })

      this.addBookableTooltipListeners()
    },
    addBookableTooltipListeners () {
      this.bookableTarget = this.svg.querySelector('#bookables > g > g.selected')

      if (this.bookableTarget) {
        this.bookableTarget.addEventListener('mouseenter', this.openTooltip)
        this.bookableTarget.addEventListener('mouseleave', this.closeTooltip)

        this.cleanupListeners = () => {
          this.bookableTarget.removeEventListener('mouseenter', this.openTooltip)
          this.bookableTarget.removeEventListener('mouseleave', this.closeTooltip)
        }
      } else {
        console.error('No bookable is selected')
      }
    },
    openTooltip (e) {
      this.positions.left = e.clientX
      this.positions.top = e.clientY
      this.showTooltip = true
    },
    closeTooltip () {
      this.showTooltip = false
    },
    zoom (map) {
      const bookableElement = this.svg.querySelector(`#bookables g#${CSS.escape(this.selected)}`)

      if (!bookableElement) {
        return
      }

      this.addClass(bookableElement, 'selected')

      const position = bookableElement.getBoundingClientRect()
      const box = this.svg.getBoundingClientRect()

      map.panBy({
        x: (position.left - box.left + (position.width - box.width) / 2) * -1,
        y: (position.top - box.top + (position.height - box.height) / 2) * -1,
      })
      map.zoom(4)
    },
    startGrab (e) {
      e.target.style.cursor = 'grab'
    },
    startDefault (e) {
      e.target.style.cursor = 'default'
    },
    startDragging (e) {
      e.target.style.cursor = 'grabbing'
    },
  },
}
</script>

<style scoped>
.svg-holder {
  @apply border border-black rounded-xl;
  @apply overflow-hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.0715014);
}
.svg-holder :deep(.floorplan) {
  width: 100vw;
  height: 250px;
  background-color: #1E2024;
}

.svg-holder :deep(#bookables g.selected *) {
  @apply fill-microsoft-blue-default;
}
</style>
