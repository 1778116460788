<template>
  <div>
    <h3 class="text-lg font-semibold">
      {{ $t('App.InvalidIntegration') }}
    </h3>
    <h5 class="text-xs font-medium text-justify text-flexopus-black/70 my-2">
      {{ $t('App.InvalidIntegrationDescription') }}
    </h5>
    <a
      target="_blank"
      href="https://help.flexopus.com/de/microsoft-outlook-plugin"
      class="text-sm text-flexopus-brand underline"
    >
      {{ $t('App.ReadMore') }}
    </a>
    <button
      class="btn btn-primary my-3"
      @click="$emit('reload')"
    >
      {{ $t('App.Reload') }}
    </button>
    <div class="divider my-6" />
    <plugin-details />
    <div class="flex justify-end">
      <button
        class="w-1/3 btn-secondary hover:border-black focus:border-microsoft-blue-default text-xs transition-all duration-200"
        @click="$emit('logout')"
      >
        {{ $t('App.SignOut') }}
      </button>
    </div>
  </div>
</template>

<script>
import PluginDetails from '@/components/PluginDetails'

export default {
  components: {
    PluginDetails,
  },
}
</script>

<style scoped>
</style>
