<template>
  <div
    v-if="building"
    :class="type"
    class="flex mt-3 transition-all duration-200 ease-in rounded pl-2 pr-4 py-3"
  >
    <label
      v-if="type === 'selective'"
      class="form-switch mr-3 ml-1"
      @click.stop
    >
      <input
        v-model="selected"
        type="checkbox"
      >
      <i />
    </label>
    <template v-else>
      <div
        class="rounded hover:bg-neutral-gray-400 w-6 h-6 flex justify-center mr-3 transition-all duration-200"
      >
        <img
          class="cursor-pointer trash-icon"
          :src="require('@/assets/img/icon-trash.svg')"
          alt="remove"
          @click.stop="$emit('remove', bookable)"
        >
      </div>
    </template>
    <div class="flex flex-col w-full min-w-0 gap-0.5">
      <h4 class="text-flexopus-black font-bold text-sm truncate">
        {{ bookable.name }}
      </h4>
      <h6 class="text-neutral-gray-700 text-xs leading-normal">
        {{ bookable.integration_email }}
      </h6>
      <h6 class="text-neutral-gray-700 text-xs leading-normal truncate">
        <bookable-status :booked="bookable.booked" />
        {{ building.name }}, {{ location.name }}
      </h6>
    </div>
    <div class="flex flex-col justify-center">
      <div
        class="cursor-pointer rounded-full hover:bg-neutral-gray-400 transition-all
        duration-200 ease-in pt-2 h-[30px] w-[30px]"
        @click="$emit('select', bookable)"
      >
        <img
          class="room-arrow"
          :src="require('@/assets/img/arrow-right.svg')"
          alt="details"
        >
      </div>
    </div>
  </div>
</template>

<script>
import BookableStatus from '@/components/BookableStatus.vue'
import outlook from '@/outlook'

export default {
  components: { BookableStatus },
  props: {
    attendees: {
      required: true,
      type: Array,
    },
    bookable: {
      required: true,
      type: Object,
    },
    locations: {
      required: true,
      type: Array,
    },
    buildings: {
      required: true,
      type: Array,
    },
    type: {
      required: false,
      type: String,
      default: 'selective',
    },
  },
  computed: {
    location () {
      return this.locations.find(l => l.id === this.bookable.location_id)
    },
    building () {
      return this.location ? this.buildings.find(b => b.id === this.location.building_id) : this.location
    },
    selected: {
      get () {
        return this.attendees.includes(this.bookable.integration_email)
      },
      set (value) {
        if (value) {
          outlook.addAttendee(this.bookable.integration_email)
        } else {
          outlook.removeAttendee(this.bookable.integration_email)
        }
      },
    },
  },
}
</script>

<style scoped>
.room-arrow {
  width: 0.5rem;
  @apply mx-3 cursor-pointer;
}

.icon {
  height: 12px;
  margin-top: 3px;
}

.removable {
  @apply bg-white border border-microsoft-blue-default;
  @apply pl-2 pr-4 py-3 rounded-md;
}

.trash-icon {
  height: 17px;
  @apply mt-1;
}
</style>
