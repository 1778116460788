<template>
  <div>
    <object
      class="loading"
      :data="require('@/assets/img/loading.svg')"
    />
    <p class="text-base text-neutral-gray-700">
      {{ loading }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  width: 4rem;
}
</style>
