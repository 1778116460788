<template>
  <div>
    <div class="flex text-neutral-gray-700 text-sm w-full">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="pl-3 py-3 pr-4 tab"
        :style="{ 'flex-basis': `${tabBasisSize}%` }"
        :aria-selected="tab.open"
        @click="openTab(index)"
      >
        <span
          v-if="tab.name"
          class="flex"
        >
          <img
            v-if="tab.img"
            :src="tab.open && tab.imgActive ? tab.imgActive : tab.img"
            :alt="tab.name"
            class="mr-2 w-[1rem]"
          >
          <span class="overflow-hidden whitespace-nowrap text-ellipsis">{{ tab.name }}</span>
        </span>
        <slot
          v-if="!tab.name"
          :name="`${tab.name}-tab`"
        />
      </div>
    </div>
    <div class="divider mb-6" />
    <div>
      <div
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <slot
          v-if="tab.open"
          :name="tab.slot"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      tabs: this.config.map((config, index) => ({ open: index === 0, ...config })),
    }
  },
  computed: {
    tabBasisSize () {
      return 100 / this.tabs.length
    },
  },
  methods: {
    openTab (index) {
      this.tabs = [...this.tabs.map((tab, pos) => pos === index ? ({ ...tab, open: true }) : ({ ...tab, open: false }))]
    },
  },
}
</script>

<style lang="scss">
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tab {
  @apply relative cursor-pointer;
  &:after {
    opacity: 0;
    content: '';
    @apply transition-all duration-200 ease-in;
    @apply bg-microsoft-blue-default rounded absolute;
    @apply left-0 bottom-[-2px];
    animation: fadeIn 0.2s ease-in;
  }

  &[aria-selected="true"] {
    @apply font-bold;

    &:after {
      opacity: 1;
      @apply h-[3px] w-full;
    }
  }
}
</style>
