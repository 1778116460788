<template>
  <div>
    <div class="flex flex-col gap-0.5 text-flexopus-black text-xs font-medium">
      <div class="flex items-start gap-2">
        <img
          class="map-icon"
          :src="require('@/assets/img/map-blue.svg')"
          alt="map icon"
        >
        <h6>
          <div>{{ building.name }}</div>
          <div>{{ location.name }}</div>
        </h6>
      </div>
      <div class="flex gap-2">
        <img
          class="map-icon"
          :src="require('@/assets/img/map-tag-blue.svg')"
          alt="map icon"
        >
        <h6>
          {{ building.address }}
        </h6>
      </div>
      <div class="flex gap-2">
        <img
          class="map-icon"
          :src="require('@/assets/img/person-blue.svg')"
          alt="user icon"
        >
        <h6 v-if="!!bookable.capacity">
          {{ $tc('App.Capacity', bookable.capacity, { capacity: bookable.capacity }) }}
        </h6>
        <h6 v-else>
          {{ $t('App.NoCapacity') }}
        </h6>
      </div>
    </div>
    <div class="divider mt-6 mb-3" />
    <div class="mb-2">
      <span class="text-neutral-gray-500 text-xs font-medium mt-2">
        {{ $t('App.EquipmentAndTags') }}
      </span>
      <div
        v-if="bookable.equipments.length > 0"
        class="flex flex-wrap gap-2 text-microsoft-blue-default text-xs font-medium"
      >
        <span
          v-for="(equipment, key) in bookable.equipments"
          :key="key"
          class="border border-microsoft-blue-default px-2 rounded-sm bg-transparent truncate max-w-full"
        >{{ equipment }}</span>
      </div>
      <div v-else>
        <h6 class="text-flexopus-black text-xs font-medium">
          {{ $t('App.NoTags') }}
        </h6>
      </div>
    </div>
    <div class="mb-2">
      <span class="text-neutral-gray-500 text-xs font-medium mt-2">
        {{ $t('App.Description') }}
      </span>
      <h6 class="text-flexopus-black text-xs font-medium">
        {{ !bookable.description ? $t('App.NoDescription') : bookable.description }}
      </h6>
    </div>
    <div class="mb-2 pb-16">
      <span class="text-neutral-gray-500 text-xs font-medium mt-2">
        {{ $t('App.Floorplan') }}
      </span>
      <location-map
        :map="location.map_url"
        :selected="bookable.code"
        :name="bookable.name"
        :capacity="bookable.capacity"
      />
    </div>
    <div class="block pb-4">
      <button
        v-if="!isSelectedAttendee"
        class="btn btn-primary mb-3"
        @click="$emit('add')"
      >
        {{ $t('App.AddRoom') }}
      </button>
      <button
        v-else
        class="btn btn-danger mb-3"
        @click="$emit('remove')"
      >
        {{ $t('App.RemoveRoom') }}
      </button>
    </div>
  </div>
</template>

<script>
import LocationMap from '@/components/LocationMap'

export default {
  components: {
    LocationMap,
  },
  props: {
    bookable: {
      required: true,
      type: Object,
    },
    locations: {
      required: true,
      type: Array,
    },
    buildings: {
      required: true,
      type: Array,
    },
    isSelectedAttendee: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    location () {
      return this.locations.find(l => this.bookable.location_id === l.id)
    },
    building () {
      return this.buildings.find(b => this.location.building_id === b.id)
    },
  },
}
</script>
