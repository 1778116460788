<template>
  <div>
    <h3 class="text-lg font-semibold">
      {{ $t('App.Plugin') }}
    </h3>
    <h5 class="text-xs font-medium text-justify text-flexopus-black/70 my-2">
      {{ $t('App.PluginInfo') }}
    </h5>
    <a
      target="_blank"
      href="https://help.flexopus.com/de/microsoft-outlook-plugin"
      class="text-sm text-flexopus-brand underline"
    >
      {{ $t('App.ReadMore') }}
    </a>
  </div>
</template>

<script>
export default {}
</script>
