<template>
  <div class="pan-zoom flex justify-center">
    <div
      v-for="(icon, key) in icons"
      :key="key"
      class="flex justify-center cursor-pointer"
      @click="$emit(icon)"
    >
      <img
        class="zoom-icon"
        :src="require(`@/assets/img/${icon}.svg`)"
        :alt="icon"
      >
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      icons: ['zoom-out', 'reset', 'zoom-in'],
    }
  },
}
</script>

<style scoped lang="scss">
.pan-zoom {
  @apply absolute text-center;
  @apply mb-2 right-0;
  bottom: -3rem;
}

.zoom-icon {
  object-fit: cover;
  height: max-content;
  @apply transition duration-500 ease-in-out;
  &:hover {
    filter: invert(16%) sepia(3%) saturate(146%) hue-rotate(349deg) brightness(92%) contrast(94%);
  }
}
</style>
