<template>
  <div class="text-flexopus-black">
    <h3 class="text-lg font-bold pb-4">
      {{ $t('App.FlexopusLogin') }}
    </h3>
    <button
      class="btn btn-primary"
      @click.prevent="$emit('login')"
    >
      {{ $t('App.Login') }}
    </button>
    <div class="divider mb-5 mt-8" />
    <plugin-details />
  </div>
</template>

<script>
import PluginDetails from '@/components/PluginDetails'
export default {
  components: {
    PluginDetails,
  },
}
</script>
