<template>
  <div id="app">
    <invalid-integration
      v-if="isNotConfigured"
      class="p-4"
      @logout="logout"
      @reload="initialize"
    />
    <template v-else-if="isValid">
      <app-tabs
        v-show="!selectedBookable"
        class="overflow-auto h-full p-4"
        :config="tabs"
      >
        <template #room-finder>
          <div v-if="showAttendees">
            <bookable-list-element
              v-for="bookable of attendeesBookables"
              :key="bookable.id"
              class="hover:bg-white hover:border-black"
              :bookable="bookable"
              :attendees="attendees"
              :locations="locations"
              :buildings="buildings"
              type="removable"
              @remove="removeAttendeeByBookable"
              @select="selectBookable"
            />
          </div>
          <div class="bookable mt-4">
            <bookable-list
              :attendees="attendees"
              :available-equipment="availableEquipment"
              :bookables="availableBookables"
              :buildings="availableBuildings"
              :locations="availableLocations"
              :loading="loading"
              :params="params"
              @select-bookable="selectBookable"
              @search="search"
            />
          </div>
        </template>
        <template #information>
          <configuration-info
            :user-name="user.name"
            :user-email="user.email"
            :tenant="tenant"
            :version="version"
            :release-date="releaseDate"
            @logout="logout"
          />
        </template>
      </app-tabs>
      <div
        v-if="selectedBookable"
        class="p-4"
      >
        <div class="flex">
          <div
            class="cursor-pointer rounded-full hover:bg-neutral-gray-400 transition-all
            duration-200 ease-in mr-4 flex items-center justify-center go-back-icon"
            @click="selectedBookable = null"
          >
            <img
              :src="require('@/assets/img/go-back.svg')"
              alt="go back image"
            >
          </div>
          <div class="flex flex-col">
            <h3
              class="text-base font-medium"
            >
              {{ selectedBookable.name }}
            </h3>
            <h5 class="text-xs font-medium text-microsoft-blue-default pb-1">
              {{ selectedBookable.integration_email }}
            </h5>
            <div class="leading-none">
              <bookable-status :booked="selectedBookable.booked" />
            </div>
          </div>
        </div>
        <div class="divider my-6" />
        <div class="bookable mt-4">
          <bookable-details
            :bookable="selectedBookable"
            :locations="locations"
            :buildings="buildings"
            :is-selected-attendee="isSelectedAttendee"
            @add="addAttendee"
            @remove="removeAttendee"
          />
        </div>
      </div>
    </template>
    <app-loading
      v-else-if="isLoading"
      :loading="$t('App.Loading')"
      class="flex flex-col h-full w-full items-center justify-center p-4"
    />
    <login-on-plugin
      v-else
      class="p-4"
      @login="login"
    />
  </div>
</template>

<script>
import AppLoading from '@/components/AppLoading'
import AppTabs from '@/components/AppTabs'
import BookableDetails from '@/components/BookableDetails'
import BookableList from '@/components/BookableList'
import BookableListElement from '@/components/BookableListElement'
import BookableStatus from '@/components/BookableStatus.vue'
import ConfigurationInfo from '@/components/ConfigurationInfo'
import InvalidIntegration from '@/components/InvalidIntegration'
import LoginOnPlugin from '@/components/LoginOnPlugin'
import InitLanguage from '@/mixins/init-language'
import outlook from '@/outlook'
import axios from 'axios'
import { computed, ref } from 'vue'

const ConfigStates = {
  LOADING: 'loading',
  NOT_CONFIGURED: 'not_configured',
  NOT_VALID: 'not_valid',
  VALID: 'valid',
}

export default {
  components: {
    BookableStatus,
    AppLoading,
    AppTabs,
    BookableDetails,
    BookableList,
    BookableListElement,
    ConfigurationInfo,
    InvalidIntegration,
    LoginOnPlugin,
  },
  mixins: [InitLanguage],
  setup () {
    const { start, end, recurrence } = outlook.useAppointmentSchedule()

    const params = ref({ only_free: 1 })
    const timeParams = computed(() => ({ start: start.value, end: end.value }))
    const searchParams = computed(() => ({ ...params.value, start: start.value, end: end.value, recurrence: recurrence.value }))

    return {
      attendees: outlook.useRequiredAttendees(),
      params,
      searchParams,
      timeParams,
      recurrence,
    }
  },
  data () {
    return {
      tenant: null,
      validConfig: ConfigStates.LOADING,
      buildings: [],
      locations: [],
      equipment: [],
      loading: false,
      bookables: [],
      initialBookables: [],
      dialog: null,
      selectedBookable: null,
      user: {
        name: null,
        email: null,
      },
      tabs: [
        {
          slot: 'room-finder',
          name: this.$t('Tabs.RoomFinder'),
          img: require('@/assets/img/search-icon.svg'),
          imgActive: require('@/assets/img/search-icon-fill.svg'),
        },
        {
          slot: 'information',
          name: this.$t('Tabs.Information'),
          img: require('@/assets/img/settings-icon.svg'),
          imgActive: require('@/assets/img/settings-icon-fill.svg'),
        },
      ],
    }
  },
  computed: {
    isSelectedAttendee () {
      return this.attendees.some(i => i === this.selectedBookable.integration_email)
    },
    configs () {
      return ConfigStates
    },
    isValid () {
      return ConfigStates.VALID === this.validConfig
    },
    isInvalid () {
      return ConfigStates.NOT_VALID === this.validConfig
    },
    isLoading () {
      return ConfigStates.LOADING === this.validConfig
    },
    isNotConfigured () {
      return ConfigStates.NOT_CONFIGURED === this.validConfig
    },
    attendeesBookables () {
      return this.initialBookables.filter(bookable => this.attendees.includes(bookable.integration_email))
    },
    availableBookables () {
      return this.bookables.filter(bookable => !this.attendees.includes(bookable.integration_email))
    },
    version () {
      return process.env.VUE_APP_MSOPLUGIN_VERSION ?? 'dev'
    },
    releaseDate () {
      return process.env.VUE_APP_BUILD_TIMESTAMP ?? 'N/A'
    },
    showAttendees () {
      return this.attendeesBookables.length > 0 && !this.selectedBookable
    },
    availableEquipment () {
      return this.equipment.filter(e => this.initialBookables.some(b => b.equipments.includes(e.name)))
    },
    availableLocations () {
      return this.locations.filter(l => this.initialBookables.some(b => b.location_id === l.id))
    },
    availableBuildings () {
      return this.buildings.filter(b => this.availableLocations.some(l => l.building_id === b.id))
    },
  },
  watch: {
    async timeParams () {
      this.initialBookables = await this.getBookables(this.timeParams)
    },
    async searchParams () {
      this.bookables = await this.getBookables(this.searchParams)

      this.loading = false
    },
    async recurrence (current, old) {
      if ((!!current) !== (!!old)) {
        this.bookables = await this.getBookables(this.searchParams)
      }
    },
  },
  async mounted () {
    this.initialize()
    this.initLanguage()
  },
  methods: {
    async getBookables (filters) {
      return (await axios.get(this.tenant + '/api/mso-plugin/v1/search', {
        params: filters,
        headers: {
          Authorization: 'Bearer ' + this.getToken(),
        },
      })).data
    },
    initialize () {
      this.validConfig = ConfigStates.LOADING
      this.tenant = outlook.getSetting('endpoint')
      const token = this.getToken()

      if (this.tenant && token) {
        axios.get(this.tenant + '/api/mso-plugin/v1/initialize', {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }).then(async ({ data }) => {
          if (!data.msgraph_configured) {
            this.validConfig = ConfigStates.NOT_CONFIGURED
            return
          }

          this.locations = data.locations
          this.buildings = data.buildings
          this.equipment = data.equipment
          this.user.name = data.user.name
          this.user.email = data.user.email
          this.validConfig = ConfigStates.VALID
          this.initialBookables = await this.getBookables(this.timeParams)
          this.search({ only_free: 1 })
        }).catch(() => {
          this.validConfig = ConfigStates.NOT_VALID
        })
      } else {
        this.validConfig = ConfigStates.NOT_VALID
      }
    },
    selectBookable (bookable) {
      this.selectedBookable = bookable
    },
    getToken () {
      return outlook.getSetting('token')
    },
    search (params) {
      this.loading = true
      this.params = params
    },
    login () {
      outlook.displayDialog(process.env.VUE_APP_MSOPLUGIN_URL + 'login.html', (message, dialog) => {
        axios.post(decodeURIComponent(message)).then(({ data }) => {
          outlook.setSetting('endpoint', data.endpoint)
          outlook.setSetting('token', data.token)
          outlook.saveSettings().then(() => this.initialize())
        })

        dialog.close()
      })
    },
    async logout () {
      const token = this.getToken()
      await axios.delete(this.tenant + '/api/mso-plugin/v1/link', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      outlook.setSetting('endpoint', null)
      outlook.setSetting('token', null)
      await outlook.saveSettings()
      this.validConfig = ConfigStates.NOT_VALID
    },
    addAttendee () {
      outlook.addAttendee(this.selectedBookable.integration_email)
      this.selectedBookable = null
    },
    removeAttendeeByBookable (bookable) {
      outlook.removeAttendee(bookable.integration_email)
    },
    removeAttendee () {
      this.removeAttendeeByBookable(this.selectedBookable)
      this.selectedBookable = null
    },
  },
}
</script>

<style scoped lang="scss">
.bookable {
  height: 100%;
}

.go-back-icon {
  width: 30px;
  height: 30px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
